<template>
    <section class="box-height bonusChargeSsc scollDom" style="margin-bottom: 70px; padding: 0 15px" id="bonusChargeSsc">
        <div class="box-title" style="padding-bottom: 20px; border: 1px #e5e5e5 solid">
            <el-row :gutter="24" style="margin-left: 0; margin-right: 0">
                <el-col :span="24" class="customTag" style="padding: 0">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane name="first">
                        <span class="tagW" slot="label">待确认</span>
                        </el-tab-pane>
                        <el-tab-pane name="second">
                        <span class="tagW" slot="label">已确认</span>
                        </el-tab-pane>
                    </el-tabs>
                </el-col>
                <el-col style="padding: 0">
                    <div
                        style="margin-bottom: 10px; padding: 0 15px"
                    >
                        <el-form :model="filter" ref="filter" label-width="100px">
                        <el-row>
                            <el-form-item
                            label="奖金表名："
                            prop="tableIds"
                            class="fl"
                            label-width="80px"
                            >
                            <el-select
                            style="width:240px"
                                v-model="filter.tableIds"
                                placeholder="请选择"
                                filterable
                                clearable
                                collapse-tags
                                multiple
                                @change="loadData"
                                popper-class="bonusCalculationSscSelect"
                            >
                                <el-option
                                v-for="(item, index) in tableList"
                                :key="'name_' + index"
                                :label="item.name"
                                :value="item.id"
                                >
                                </el-option>
                            </el-select>
                            </el-form-item>
                            <el-form-item
                            label="所属年份："
                            prop="yearValue"
                            class="fl"
                            label-width="90px"
                            >
                            <el-date-picker
                            style="width:240px"
                                v-model="filter.yearValue"
                                value-format="yyyy"
                                type="year"
                                :clearable="false"
                                placeholder="请选择所属年份"
                                @change="loadData()"
                            >
                            </el-date-picker>
                            </el-form-item>
                            <el-form-item
                            label="预计发放时间："
                            prop="planPayDate"
                            class="fl"
                            label-width="120px"
                            >
                            <el-date-picker
                            style="width:240px"
                                v-model="filter.planPayDate"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="loadData()"
                            >
                            </el-date-picker>
                            </el-form-item>
                            <el-form-item label="顾问："
                            prop="userIds"
                            class="fl"
                            label-width="80px"
                            >
                                <el-select
                                style="width:240px"
                                    clearable
                                    filterable
                                    collapse-tags
                                    multiple
                                    v-model="filter.userIds"
                                    placeholder="请选择"
                                    @change="loadData()"
                                >
                                    <el-option
                                    v-for="i in userList"
                                    :key="i.id"
                                    :label="i.name"
                                    :value="i.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item v-if="activeName=='second'" label="发放状态："
                            prop="bonusStatus"
                            class="fl"
                            label-width="80px"
                            >
                                <el-select
                                style="width:240px"
                                    clearable
                                    filterable
                                    v-model="filter.bonusStatus"
                                    placeholder="请选择"
                                    @change="loadData()"
                                >
                                    <el-option label="待发放" value="TO_PAY"></el-option>
                                    <el-option label="已发放" value="PAID"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-row>
                        </el-form>
                        <div class="flexRow" style="justify-content: space-between">
                        <div>
                            <el-button
                            v-if="activeName=='first'"
                            size="mini"
                            @click="batchRecognize()"
                            :disabled="chioceIds.length == 0"
                            >批量确认</el-button
                            >
                        </div>
                        </div>
                    </div>
                    <div>
                        <measurementsTable
                            :key="measurementsTableNumRecognize"
                            ref="measurementsTableNumRecognize"
                            @getChioceId="getChioceId"
                            @batchRecognize='batchRecognize'
                            :filter="filter"
                            tableStatus="TO_PAY_RECONGNIZE"
                            style="margin-bottom: 30px"
                        ></measurementsTable>
                    </div>
                </el-col>
            </el-row>
        </div>
    </section>
</template>

<script>
import {
  bonusDataTableOptions,
  getEnableUserBaseInfos, 
  permissionTree,//获取顾问
  bonusConfirm
} from "../../api/api";
import measurementsTable from "../../components/report/measurementsTable";
export default {
    components: {
        measurementsTable,
    },
    data(){
        return{
            activeName:'first',
            filter:{
                confirmed:false,
                tableIds:[],
                yearValue:null,
                planPayDate:[],
                userIds:[],
                bonusStatus:null,
                page: {
                    current: 1,
                    size: 50,
                    total: 0,
                },
            },
            tableList: [],
            userList: [],
            chioceIds:[],
            ListData:[],
            listLoading:false,
            options1: [],
            getuserIds: [],
            cleanId: null,
            measurementsTableNumRecognize: 0,
        }
    },
    created(){
        this.getBonusDataTableOptions()
        this.loadUserBase()
        this.permissionTree()
        this.loadData();
    },
    methods:{
        handleClick(tab, event) {
            console.log(tab, event);
            this.activeName = tab.name;
            this.filter = {
                confirmed:false,
                tableIds:[],
                yearValue:null,
                planPayDate:[],
                userIds:[],
                bonusStatus:null,
                page: {
                    current: 1,
                    size: 50,
                    total: 0,
                },
            };
            this.filter.confirmed=tab.name=='first'?false:true;
            this.measurementsTableNumRecognize++;
            this.getBonusDataTableOptions();
            this.loadData();
        },
          //奖金选项表
        getBonusDataTableOptions() {
            bonusDataTableOptions({ status:  this.activeName=='first'?'TO_PAY':'' }).then((res) => {
                if (res.success) {
                    this.tableList = res.result;
                }
            });
        },
        loadUserBase() {
            //获取用户列表
            getEnableUserBaseInfos().then((res) => {
                if (res.success) {
                    this.userList = res.result;
                }
            });
        },
          // 顾问
        permissionTree() {
            permissionTree({
                type: "顾问",
                showLeaveStatus: true,
                showDisableUser: true,
            }).then((res) => {
                if (res.success) {
                this.options1 = res.result;
                this.$refs.measurementsTableNumRecognize.handelConsultant(this.options, true);
                }
            });
        },
        getSearch(val) {
            // const _nodeId = this.$refs.dataRef.getCheckedNodes(true);
            // let isChecked;
            this.filter.userIds = this.getuserIds.map((i) => {
                return i[i.length - 1];
            });
            this.loadData();
        },
        filterFun(data, value) {
            // 弹窗 - 拼音过滤
            return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        },
        batchRecognize(id){
            console.log(4555)
            let ids=[]
            ids=id?[id]:this.chioceIds
            let div =
          "<p style='font-weight:bold;font-size:16px;color:#333;margin-bottom:10px'>确定奖金无误？ ";
            this.$confirm(div, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                customClass: "altCls",
                type: "warning",
                showClose: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
          dangerouslyUseHTMLString: true,
            })
            .then(() => {
                bonusConfirm({ids:ids}).then((res) => {
                    if (res.success) {
                        this.$message.success("操作成功");
                        this.$refs.measurementsTableNumRecognize.loadList();
                    }
                });
            })
            .catch(() => {
            });
        },
        handleSelectionChange(val) {
            //表格选择
            this.multipleSelection = val;
            if (this.cleanId !== null) {
                this.chioceIds = val.map((i) => {
                if (i.id !== this.cleanId) {
                    return i.id;
                }
                });
            } else {
                this.chioceIds = val.map((i) => i.id);
            }
        },
        handleSelect(rows, row) {
            let selected = rows.length && rows.indexOf(row) !== -1;
            if (!selected) {
                this.cleanId = row.id;
            } else {
                this.cleanId = null;
            }
        },
        loadData(){
            this.filter.page.size=50
            this.filter.page.current=1
            this.loadList()
        },
        loadList(){
            this.$nextTick(() => {
                this.$refs.measurementsTableNumRecognize.loadList();
            });
        },
        //获取选中id
        getChioceId(ids) {
            this.chioceIds = ids;
            console.log(ids)
        },
        changeOptions(data) {
            this.options = data;
        },
    }
}
</script>

<style lang="scss">
.customTag {
  position: relative;
  .el-tabs__item {
    line-height: 50px;
    height: 50px;
    font-size: 14px;
    color: #606266;
  }
  .el-tabs__nav-scroll {
    padding: 0 20px;
  }
  .el-tabs__item.is-active {
    color: #e60012 !important;
  }
}
.bonusChargeSsc{
    .el-tag.el-tag--info{
        max-width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .el-select__tags-text{
        display: block;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>